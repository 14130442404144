import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Whitespace, {
  WhitespaceProps,
} from '@algbra/ui/components/Whitespace/Whitespace'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import { resolveAsAttachment, resolveAsImage } from '@algbra/ui/resolvers'
import { map, mapIndexed } from 'rambdax'

import styles from './BoxedList.module.scss'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import classNames from 'classnames'
import Heading from '@algbra/ui/components/Heading/Heading'
import { GatsbyImage } from 'gatsby-plugin-image'
import Icon from '@algbra/ui/components/Icon/Icon'
import { getIconByName } from '@algbra/ui/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'

export type ZoneBoxedListProps = {
  __typename: 'StrapiGQL_ComponentZonesBoxedList'
  id: string
  boxedListTitle: string
  description: string
  items: BoxedListItemProps[]
  inviteCode?: string
}

export type BoxedListItemProps = {
  id: string
  description: string
  image: GatsbyResolvedAsset
  isGreen?: boolean
}

export function BoxedListItem({
  image,
  description,
  isGreen,
}: BoxedListItemProps): JSX.Element {
  return (
    <Column auto className={styles.boxCol}>
      <div className={classNames(styles.box, { [styles.boxBg]: isGreen })}>
        <GatsbyImage
          image={resolveAsImage(image)}
          alt={description}
          loading="lazy"
          className={styles.boxImage}
        />
        <FreeText type="md" innerHTML={description} className={styles.boxText}>
          {description}
        </FreeText>
      </div>
    </Column>
  )
}

export default function ZoneBoxedList({
  boxedListTitle,
  description,
  items,
  inviteCode,
}: ZoneBoxedListProps) {
  const isClient = typeof window !== 'undefined'
  const [isGreen, setBackgroundColor] = useState<boolean>(false)
  useEffect(() => {
    const green =
      inviteCode === 'Ube1IF' || inviteCode === 'ube1if' ? true : false
    setBackgroundColor(green)
  }, [inviteCode, isClient])

  return (
    <Whitespace type="none">
      <Container className={styles.boxList}>
        <Row
          className={classNames(styles.boxRow, { [styles.boxRowBg]: isGreen })}
        >
          {map(
            item => (
              <BoxedListItem {...item} key={item.id} isGreen={isGreen} />
            ),
            items || []
          )}
        </Row>
      </Container>
    </Whitespace>
  )
}
