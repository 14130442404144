import { Container } from '@algbra/ui/components/Grid/Grid'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import { resolveAsImage } from '@algbra/ui/resolvers'
import { map } from 'rambdax'

import styles from './EventProgramme.module.scss'
import Heading from '@algbra/ui/components/Heading/Heading'
import { GatsbyImage } from 'gatsby-plugin-image'
import Icon from '@algbra/ui/components/Icon/Icon'
import { getIconByName } from '@algbra/ui/icons'

export type ZoneEventProgrammeProps = {
  id: string
  title: string
  date: string
  location: string
  eventSponsors: EventSponsorItemProps[]
  programmeDetail: ProgrammeDetailItemProps[]
  programmeDetailDisclaimer: string
}

export type EventSponsorItemProps = {
  logo: GatsbyResolvedAsset
  description: string
}

export type ProgrammeDetailItemProps = {
  time: string
  detail: string
}

export function ProgrammeDetailItem({
  time,
  detail,
}: ProgrammeDetailItemProps): JSX.Element {
  return (
    <div className={styles.eventItem}>
      <div className={styles.time}>
        <Icon icon={getIconByName('schedule')} size={24} />
        <span>{time}</span>
      </div>
      <div className={styles.detail}>
        <span>{detail}</span>
      </div>
    </div>
  )
}

export function EventSponsorItem({
  logo,
  description,
}: EventSponsorItemProps): JSX.Element {
  return (
    <div className={styles.sponsorItem}>
      <div className={styles.logo}>
        <GatsbyImage
          image={resolveAsImage(logo)}
          alt="Event Sponsor"
          className={styles.image}
          role="presentation"
          loading="lazy"
        />
      </div>
      <div className={styles.description}>
        <span>{description}</span>
      </div>
    </div>
  )
}

export default function ZoneEventProgramme({
  title,
  date,
  location,
  programmeDetail,
  programmeDetailDisclaimer,
  eventSponsors,
}: ZoneEventProgrammeProps) {
  return (
    <Whitespace type="none" className={styles.whitespace}>
      <div className={styles.icon}>
        <Icon icon={getIconByName('arrow-circle-down')} size={36} />
      </div>
      <Container className={styles.container}>
        <Container className={styles.innerContainer}>
          <Heading type="lg" className={styles.title}>
            {title}
          </Heading>
          <div className={styles.infoList}>
            <div className={styles.infoItem}>
              <Icon icon={getIconByName('event')} size={24} />
              <span>{date}</span>
            </div>
            <div className={styles.infoItem}>
              <Icon icon={getIconByName('location')} size={24} />
              <span>{location}</span>
            </div>
          </div>

          <div className={styles.eventList}>
            {map(
              item => (
                <ProgrammeDetailItem {...item} key={item.id} />
              ),
              programmeDetail || []
            )}
            <p className={styles.disclaimer}>{programmeDetailDisclaimer}</p>
          </div>
        </Container>

        {eventSponsors && (
          <Container className={styles.sponsorContainer}>
            <Heading type="sm" className={styles.sponsorTitle}>
              Event Sponsors
            </Heading>
            <div className={styles.sponsorList}>
              {map(
                item => (
                  <EventSponsorItem {...item} key={item.id} />
                ),
                eventSponsors || []
              )}
            </div>
          </Container>
        )}
      </Container>
    </Whitespace>
  )
}
