import Button from '@algbra/ui/components/Button/Button'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Input from '@algbra/ui/components/Input/Input'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import Config from '../../config'
import { ReactComponent as FCAApprovalIcon } from '../../images/referrals/fca-regulated-icon.svg'
import * as animationData from './alerts-success.json'
import styles from './ReferralsForm.module.scss'
import Checkbox from '@algbra/ui/components/Checkbox/Checkbox'

export const schema = yup.object().shape({
  email: yup.string().email().required().label('E-mail'),
})

function ReferralsFormWithoutInviteCode({
  slug,
  isBlackCard,
  showCardImages,
}: {
  slug: string
  isBlackCard: boolean
  showCardImages: boolean
}) {
  const [isLoading, setLoading] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const [isTermsChecked, setTermsChecked] = useState<boolean>(false)

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
    criteriaMode: 'firstError',
    defaultValues: {
      slug,
      email: '',
    },
  })
  const onFormValid = async values => {
    const token = await recaptchaRef.current.executeAsync()
    setLoading(true)
    const formData = getValues()
    axios
      .post('/.netlify/functions/referrals', {
        ...formData,
        token,
      })
      .then(() => {
        setLoading(false)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'gtm_referral_sign_up',
        })
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset()
        }
        navigate('/referrals-success')
      })
      .catch(e => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          error: e.response?.data?.error,
          event: 'referral_error',
        })
        setLoading(false)
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset()
        }
        navigate('#failed-registration')
        window.alert(
          'There was an error trying to send your request. Please try again later.'
        )
      })
  }

  return (
    <Whitespace type="none">
      <Container className={styles.root}>
        {showCardImages && isBlackCard ? (
          <StaticImage
            src="../../images/referrals/card-left-black.png"
            alt="Algbra Card"
            layout="constrained"
            width={708}
            quality={100}
            placeholder="blurred"
            loading="eager"
            className={styles.cardLeftBlack}
          />
        ) : (
          <StaticImage
            src="../../images/referrals/card-pink.png"
            alt="Algbra Card"
            layout="constrained"
            width={852}
            quality={100}
            placeholder="blurred"
            loading="eager"
            className={styles.cardPink}
          />
        )}

        <StaticImage
          src="../../images/referrals/card-black.png"
          alt="Algbra Card"
          layout="constrained"
          width={715}
          quality={100}
          placeholder="blurred"
          loading="eager"
          className={styles.cardBlack}
        />
        <div className={styles.container}>
          <form id="referrals-page">
            <ReCAPTCHA
              size="invisible"
              ref={recaptchaRef}
              sitekey={Config.reCaptchaKey}
            />

            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  id="ID-004-edit-email-field"
                  placeholder="E-mail"
                  type="email"
                  controlClassName={styles.input}
                  className={styles.inputContainer}
                  error={error}
                />
              )}
            />

            <div style={{ paddingBottom: 20 }}>
              <Checkbox
                name="marketing"
                checked={isTermsChecked}
                onChange={isChecked => setTermsChecked(isChecked)}
              >
                <div>
                  <FreeText type="m">Terms & Conditions</FreeText>
                  <FreeText type="sm" color="secondary">
                    By entering your email, you agree to our{' '}
                    <Permalink
                      to="/privacy-policy"
                      isBlank={true}
                      isExternal={true}
                    >
                      Privacy Policy
                    </Permalink>
                  </FreeText>
                </div>
              </Checkbox>
            </div>

            <Button
              onClick={handleSubmit(onFormValid)}
              id="ID-005-submit-button"
              block={true}
              theme="primary"
              disabled={!isTermsChecked}
              loading={isLoading}
            >
              Get Download Link
            </Button>
          </form>
        </div>
      </Container>
    </Whitespace>
  )
}

export default ReferralsFormWithoutInviteCode
