import { graphql, PageProps } from 'gatsby'
import { map } from 'rambdax'
import { useEffect, useState } from 'react'
import axios from 'axios'
import PageLayout from '../components/PageLayout/PageLayout'
import ReferralsForm from '../components/ReferralsForm/ReferralsForm'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags, SEOFields } from '../utils/metaTags'
import ZoneInlinePressLogoList, {
  ZoneInlinePressLogoListProps,
} from '../zones/InlinePressLogoList/InlinePressLogoList'
import ZoneProductFeatureBoxList, {
  ZoneProductFeatureBoxListProps,
} from '../zones/ProductFeatureBoxList/ProductFeatureBoxList'
import ReferralsFormWithoutInviteCode from '../components/ReferralsForm/ReferralsFormWithoutInviteCode'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { resolveAsImage } from '@algbra/ui/resolvers'
import styles from './referrals.module.scss'
import Heading from '@algbra/ui/components/Heading/Heading'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import { getIconByName } from '@algbra/ui/icons'
import Icon from '@algbra/ui/components/Icon/Icon'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import ZoneBigFeatureBlock from '../zones/BigFeatureBlock/BigFeatureBlock'
import ZoneBoxedList, { ZoneBoxedListProps } from '../zones/BoxedList/BoxedList'
import ZoneEventProgramme, {
  ZoneEventProgrammeProps,
} from '../zones/EventProgramme/EventProgramme'
import classNames from 'classnames'

export type ZoneContentTypes =
  | ZoneInlinePressLogoListProps
  | ZoneProductFeatureBoxListProps
  | ZoneBoxedListProps

export type ReferralCampaignType = {
  slug: string
  title?: string
  subtitle?: string
  image?: GatsbyResolvedAsset
  terms?: string
  seo?: SEOFields
  eventProgramme?: ZoneEventProgrammeProps
}

export default function LayoutReferral({
  pageContext,
  data,
  location,
  params,
}) {
  const [isInviteFlowEnabled, setIsInviteFlowEnabled] = useState<
    boolean | null
  >(null)
  const [showCardImages, setShowCardImages] = useState<boolean>(false)

  const zones: ZoneContentTypes[] = data?.strapiGQL?.page.contentZones

  const referralCampaignData: ReferralCampaignType =
    data.strapiGQL.referralCampaigns?.find(
      referralCampaign =>
        referralCampaign.slug.toLowerCase() === params.inviteCode?.toLowerCase()
    )

  const subpageHeaderData = {
    title: referralCampaignData?.title || pageContext.title,
    summary: referralCampaignData?.subtitle || pageContext.subtitle,
    metaTags: getMetaTags({
      ...pageContext,
      ...pageContext.referralPageSeo,
      ...referralCampaignData,
    }),
  }

  useEffect(() => {
    setShowCardImages(true)
    axios.get('/.netlify/functions/isInviteFlowEnabled').then(response => {
      setIsInviteFlowEnabled(response.data.isInviteFlowEnabled)
    })
  }, [])

  const inviteCode = referralCampaignData?.slug || params.inviteCode
  const isWWF = inviteCode === 'Ube1IF' || inviteCode === 'ube1if'
  const isPacman = inviteCode === 'lOsThq' || inviteCode === 'losthq'
  const isLebab = inviteCode === 'OB9hSb' || inviteCode === 'ob9hsb'
  return (
    <PageLayout simpleHeader={true}>
      {referralCampaignData?.image && (
        <GatsbyImage
          image={resolveAsImage(referralCampaignData?.image)}
          alt={pageContext.title}
          className={classNames(styles.image, { [styles.imageLebab]: isLebab })}
        />
      )}

      <SubpageHeader className={styles.referralHeader} {...subpageHeaderData} />

      {map(item => {
        switch (item.__typename) {
          case 'StrapiGQL_ComponentZonesBoxedList':
            return (
              <ZoneBoxedList
                key={item.id}
                {...item}
                inviteCode={params.inviteCode}
              />
            )
          case 'StrapiGQL_ComponentZonesPressLogoList':
            return <ZoneInlinePressLogoList key={item.id} {...item} />
          case 'StrapiGQL_ComponentZonesProductFeatureBoxList':
            return <ZoneProductFeatureBoxList key={item.id} {...item} />
          default:
            return null
        }
      }, zones)}

      {isInviteFlowEnabled ? (
        <ReferralsForm
          slug={inviteCode}
          isBlackCard={!!referralCampaignData?.eventProgramme}
          showCardImages={showCardImages}
        />
      ) : (
        <ReferralsFormWithoutInviteCode
          slug={inviteCode}
          isBlackCard={!!referralCampaignData?.eventProgramme}
          showCardImages={showCardImages}
        />
      )}

      {referralCampaignData?.eventProgramme && (
        <ZoneEventProgramme {...referralCampaignData.eventProgramme} />
      )}

      {isWWF && (
        <Whitespace type="none" hasAnimation={false}>
          <div className={styles.fullImage}>
            <StaticImage
              src="../images/referrals/bottom-wwf.png"
              alt="Algbra Card"
              layout="constrained"
              width={360}
              quality={100}
              placeholder="blurred"
              loading="eager"
            />
          </div>
        </Whitespace>
      )}
      {isPacman && (
        <Whitespace type="md" hasAnimation={false}>
          <div className={styles.fullImage}>
            <StaticImage
              src="../images/referrals/bottom-pacman.png"
              alt="Algbra Card"
              layout="constrained"
              width={852}
              quality={100}
              placeholder="blurred"
              loading="eager"
            />
          </div>
        </Whitespace>
      )}

      {referralCampaignData?.terms && (
        <Whitespace type="none">
          <div className={styles.termsWrapper}>
            <Container className={styles.termsContainer}>
              <div
                className={'global-text-content'}
                dangerouslySetInnerHTML={{
                  __html: referralCampaignData?.terms,
                }}
              ></div>
            </Container>
          </div>
        </Whitespace>
      )}
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      page(id: $id) {
        id
        slug
        title
        subtitle
        contentZones {
          __typename
          ... on StrapiGQL_ComponentZonesProductFeatureBoxList {
            id
            title
            features {
              id
              title
              color
              body
              contentAlignment
              isAvailable
              image {
                id
                url
                ext
                resolved {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 555
                      formats: WEBP
                      webpOptions: { quality: 100 }
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          ... on StrapiGQL_ComponentZonesPressLogoList {
            id
            title
            list {
              image {
                id
                url
                ext
                width
                height
                resolved {
                  publicURL
                }
              }
              alternativeText
              id
            }
          }
          ... on StrapiGQL_ComponentZonesBoxedList {
            id
            boxedListTitle: title
            description
            items {
              image {
                id
                url
                ext
                resolved {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 48
                      formats: WEBP
                      webpOptions: { quality: 100 }
                      placeholder: BLURRED
                    )
                  }
                }
              }
              description
              id
            }
          }
        }
        SEO {
          title
          description
          image {
            id
            url
            ext
            resolved {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: PNG
                  webpOptions: { quality: 100 }
                )
              }
            }
          }
        }
      }
      referralCampaigns {
        id
        slug
        title
        subtitle
        terms
        eventProgramme {
          id
          title
          date
          location
          programmeDetail {
            time
            detail
          }
          programmeDetailDisclaimer
          eventSponsors {
            logo {
              id
              url
              ext
              resolved {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    height: 100
                    formats: WEBP
                    webpOptions: { quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            description
          }
        }
        image {
          id
          url
          ext
          resolved {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 220
                formats: WEBP
                webpOptions: { quality: 100 }
                placeholder: BLURRED
              )
            }
          }
        }
        seo: SEO {
          title
          description
          image {
            id
            url
            ext
            resolved {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  formats: PNG
                  webpOptions: { quality: 100 }
                )
              }
            }
          }
        }
      }
    }
  }
`
