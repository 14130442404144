import Button from '@algbra/ui/components/Button/Button'
import Caption from '@algbra/ui/components/Caption/Caption'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Input from '@algbra/ui/components/Input/Input'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import Select from '@algbra/ui/components/Select/Select'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useMemo, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import Lottie from 'react-lottie'
import * as yup from 'yup'
import Config from '../../config'
import { ReactComponent as FCAApprovalIcon } from '../../images/referrals/fca-regulated-icon.svg'
import * as animationData from './alerts-success.json'
import styles from './ReferralsForm.module.scss'

export const schema = yup.object().shape({
  firstName: yup.string().required().label('Name'),
  lastName: yup.string().label('Last Name'),
  email: yup.string().email().required().label('E-mail'),
  os: yup.string().required().label('Device Type').nullable(),
})

const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

function ReferralsForm({
  slug,
  isBlackCard,
  showCardImages,
}: {
  slug: string
  isBlackCard: boolean
  showCardImages: boolean
}) {
  const [isSubmitted, setSubmitted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const deviceTypes = useMemo(
    () => [
      { value: 'IOS', label: 'iOS' },
      { value: 'ANDROID', label: 'Android' },
    ],
    []
  )
  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(schema),
    criteriaMode: 'firstError',
    defaultValues: {
      slug,
      firstName: '',
      lastName: '',
      email: '',
      os: null,
    },
  })
  const onFormValid = async values => {
    const token = await recaptchaRef.current.executeAsync()
    setLoading(true)
    const formData = getValues()
    axios
      .post('/.netlify/functions/referrals', {
        ...formData,
        os: [formData.os],
        lastName: formData.lastName || ' ',
        token,
      })
      .then(() => {
        setLoading(false)
        setSubmitted(true)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'gtm_referral_sign_up',
        })
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset()
        }
        navigate('/referrals-success')
      })
      .catch(e => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          error: e.response?.data?.error,
          event: 'referral_error',
        })
        setLoading(false)
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset()
        }
        navigate('#failed-registration')
        window.alert(
          'There was an error trying to send your request. Please try again later.'
        )
      })
  }

  return (
    <Whitespace type="none">
      <Container className={styles.root}>
        {showCardImages && isBlackCard ? (
          <StaticImage
            src="../../images/referrals/card-left-black.png"
            alt="Algbra Card"
            layout="constrained"
            width={708}
            quality={100}
            placeholder="blurred"
            loading="eager"
            className={styles.cardLeftBlack}
          />
        ) : (
          <StaticImage
            src="../../images/referrals/card-pink.png"
            alt="Algbra Card"
            layout="constrained"
            width={852}
            quality={100}
            placeholder="blurred"
            loading="eager"
            className={styles.cardPink}
          />
        )}

        <StaticImage
          src="../../images/referrals/card-black.png"
          alt="Algbra Card"
          layout="constrained"
          width={715}
          quality={100}
          placeholder="blurred"
          loading="eager"
          className={styles.cardBlack}
        />
        <div className={styles.container}>
          {!isSubmitted && (
            <form id="referrals-page">
              <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={Config.reCaptchaKey}
              />
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="ID-004-edit-name-field"
                    placeholder="First Name"
                    controlClassName={styles.input}
                    className={styles.inputContainer}
                    error={error}
                  />
                )}
              />

              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="ID-004-edit-lastname-field"
                    placeholder="Last Name"
                    controlClassName={styles.input}
                    className={styles.inputContainer}
                    error={error}
                  />
                )}
              />

              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="ID-004-edit-email-field"
                    placeholder="E-mail"
                    type="email"
                    controlClassName={styles.input}
                    className={styles.inputContainer}
                    error={error}
                  />
                )}
              />

              <Controller
                control={control}
                name="os"
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    id="ID-004-edit-device-field"
                    placeholder="Select your device"
                    controlClassName={styles.input}
                    className={styles.inputContainer}
                    error={error}
                    items={deviceTypes}
                  />
                )}
              />

              <FreeText className={styles.helpText} type="sm">
                By entering your email, you agree to our{' '}
                <Permalink
                  to="/privacy-policy"
                  isBlank={true}
                  isExternal={true}
                >
                  Privacy Policy
                </Permalink>
              </FreeText>
              <Button
                onClick={handleSubmit(onFormValid)}
                id="ID-005-submit-button"
                block={true}
                theme="primary"
                loading={isLoading}
              >
                Get Download Link
              </Button>
            </form>
          )}
          {isSubmitted && (
            <div
              className={styles.submitted}
              id="newsletter-referrals-page--success"
            >
              <Lottie options={lottieOptions} height={60} width={60} />
              <Heading level={3} type="sm" className={styles.title}>
                You’re on board!{' '}
                <span role="img" aria-label="plant and rocket emoji">
                  🌱🚀
                </span>
              </Heading>
              <Caption type="md" className={styles.description}>
                You’ve just joined the limited number of people who get to
                experience the Algbra App before it becomes publicly accessible.
                <br />
                <br />
                <Permalink
                  to="https://apps.apple.com/gb/app/algbra-ethical-banking/id1610109395"
                  isExternal={true}
                  className={styles.link}
                  isBlank={true}
                >
                  Download the Algbra App
                </Permalink>{' '}
                on the App Store and look out for an email from us with your
                unique invitation code.
              </Caption>
            </div>
          )}
        </div>
      </Container>
    </Whitespace>
  )
}

export default ReferralsForm
